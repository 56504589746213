import React from 'react';

// @ts-ignore
const FlameCounter = ({ count, points }) => {
    return (
        <div className="flex items-center bg-white rounded-md shadow-md">
            <div>
                <div className='relative border-r w-full p-2'>
                    <img src="flame-icon.svg" alt="Flame" className="w-8 h-8"/>
                    <div
                        className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/3 font-bold text-xl">
                        {count}
                    </div>
                </div>
            </div>
            <span className='text-sm p-2'>{points} pts</span>
        </div>
    );
};

export default FlameCounter;
